import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useState } from "react";
import InfoCard from "../components/InfoCard";
import Pricing from "../components/Pricing";
import { addToWaitlist } from "../services/WaitlistService";

const LandingPage = () => {
	const [wlEmailId, setWlEmailId] = useState("");
	const [wlRefferalLink, setSlRefferalLink] = useState("");
	const [wlAlert, setWlAlert] = useState<{ severity: AlertColor; message: string; color: any }>({
		severity: "info",
		message: "",
		color: "primary",
	});
	const [wlAlertOpen, setWlAlertOpen] = useState(false);

	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};
	const submitToWaitlist = () => {
		if (validateEmail(wlEmailId)) {
			addToWaitlist(wlEmailId)
				.then((refferalLink) => {
					setWlAlert({
						severity: "success",
						message: "You have joined the waitlist",
						color: "primary",
					});
					return setSlRefferalLink(refferalLink);
				})
				.catch((err) => {
					setWlAlert({
						severity: "error",
						message: err.message,
						color: "error",
					});
				})
				.then(() => setWlAlertOpen(true));
		} else {
			setWlAlert({
				severity: "error",
				message: "Invalid Email address",
				color: "error",
			});
			setWlAlertOpen(true);
		}
	};

	const handleClose = () => {
		setWlAlertOpen(false);
	};

	return (
		<div className='landingPage'>
			<video id='background-video' autoPlay loop muted poster='img/landing.png'>
				<source src='videos/landing-5.mp4' type='video/mp4' />
			</video>
			<section id='main'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8 g-fullheight--md'>
							<div id='joinTheWaitlist' className='g-padding-y-125--xs'>
								<h1 className='g-color--white g-font-size-80--lg g-font-size-60--md g-font-size-50--xs g-font-weight--500 g-letter-spacing--1 g-line-height--2 animated floatInUp floating10 g-margin-t-40--xs g-margin-t-0--md '>
									The everything app,<br></br>for content
								</h1>
								<p className='g-color--white-opacity g-font-size-20--xs g-font-weight--400 g-line-height--md g-margin-b-30--xs  animated floatInUp floating20'>
									Project management, collaboration, asset management,
									<br /> feedback, releases and everything you need to drive your content creation journey
								</p>
								<div className='input-group g-width-450--md g-width-350--xs g-margin-b-0--xs  animated floatInUp'>
									<a target='_blank' href='https://cal.com/shubhamjadhav/gobananas-demo'>
										<span className='s-btn s-btn--sm s-btn--primary-bg g-radius--50'>
											Book a demo <i className='material-symbols-sharp'>chevron_right</i>
										</span>
									</a>
									{/* <input
									type='search'
									value={wlEmailId}
									onChange={(event) => {
										setWlEmailId(event.target.value);
									}}
									className='form-control s-form-v2__input g-radius--left-50 g-radius--right-0 g-hor-border-0--right'
									placeholder='Enter your email'
								/>
								<span className='input-group-btn'>
									<button
										onClick={() => submitToWaitlist()}
										className='s-btn s-btn--md s-btn--primary-bg g-radius--right-50 g-font-size-10--xs g-radius--left-0 g-hor-border-0--left g-padding-x-25--xs'>
										<div className='material-symbols-sharp'>arrow_forward</div>
									</button>
								</span> */}
								</div>
							</div>
						</div>
						{/* <div className='col-xs-12 col-md-5'>
						<div className='landingPageGirl g-padding-y-60--xs g-padding-y-60--md g-margin-t-50--md g-margin-t-0--xs animated floatInUp'>
							<img className='g-width-400--lg g-width-350--md g-width-350--xs' src={"/img/landingPageGirl.jpg"} alt='landingPageGirl' />
						</div>
					</div> */}
					</div>
				</div>
			</section>
			<section id='product'>
				<div className='g-bg-color--white'>
					<div id='js__scroll-to-section' className='container g-padding-y-80--xs g-padding-y-125--xsm'>
						<div className='row g-row-col--5 g-margin-b-80--xs g-margin-b-100--md'>
							<div className='col-sm-8 g-hor-centered-row__col'>
								<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs'>
									Project Management
								</p>
								<h2 className='g-font-size-32--xs g-font-size-48--sm g-margin-b-25--xs'>Create your team's perfect workflows in minutes</h2>
								<p className='g-font-size-18--sm'>
									Replace the hassle of managing 11-12 different tools with one streamlined app—no lengthy integrations required. Begin by setting up workflows
									to organize tasks, assign them efficiently, and connect them to projects to keep everything on track, all in one place.
								</p>
							</div>
						</div>
						<img src='img/workflow.png' className='display-img g-radius--10' width={"100%"} />
					</div>
				</div>

				<div className='g-bg-color--white'>
					<div id='js__scroll-to-section' className='container g-padding-y-80--xs g-padding-y-125--xsm'>
						<div className='row g-row-col--5 g-margin-b-80--xs g-margin-b-100--md'>
							<div className='col-sm-8 g-hor-centered-row__col'>
								<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs'>File Management</p>
								<h2 className='g-font-size-32--xs g-font-size-48--sm g-margin-b-25--xs'>No More Permission Roadblocks — Upload Effortlessly, 2x Faster</h2>
								<p className='g-font-size-18--sm'>
									Streamline your uploads with built-in version control and seamless access control, no more waiting for approvals or jumping through hoops or
									folder management. Just upload and get started, hassle-free
								</p>
							</div>
						</div>
						<img src='img/play.png' className='display-img g-radius--10 g-margin-l-0--xs g-margin-l-60--md' />
						<img src='img/multiple-uploads.png' className='display-img display-img-2 g-radius--10' />
						<div className='row infoCardWrapper'>
							<div className='col-md-4'>
								<InfoCard
									icon={"flash_on"}
									heading={"Lightening fast uploads"}
									content={"Move large and numerous assets in quick, secure, orderly fashion across teams and projects from start to finish."}
								/>
							</div>
							<div className='col-md-4'>
								<InfoCard
									icon={"share"}
									heading={"Version Control"}
									content={"Easily upload new versions, keep every iteration organized until you get it right."}
								/>
							</div>
							<div className='col-md-4'>
								<InfoCard
									icon={"play_circle"}
									heading={"Seamless Permissions"}
									content={"Automatic reference tracking keeps permissions aligned, so every collaboration runs smoothly"}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='g-bg-color--white'>
					<div id='js__scroll-to-section' className='container g-padding-y-80--xs g-padding-y-125--xsm'>
						<div className='row g-row-col--5 g-margin-b-80--xs g-margin-b-100--md'>
							<div className='col-sm-8 g-hor-centered-row__col'>
								<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs'>
									Feedbacks & Approvals
								</p>
								<h2 className='g-font-size-32--xs g-font-size-48--sm g-margin-b-25--xs'>Review with great accuracy and control</h2>
								<p className='g-font-size-18--sm'>
									Deliver precise, actionable feedback with advanced commenting tools, making collaboration and approvals seamless.{" "}
								</p>
							</div>
						</div>
						<video autoPlay loop muted className='display-img g-radius--10' width={"100%"} src='videos/comments.mov' />
					</div>
				</div>
			</section>

			<div className='glass-bg'>
				<div className='g-container--sm g-text-center--xs g-padding-y-80--xs g-padding-y-120--md'>
					<div className='g-margin-b-0--xs'>
						<h2 className='g-font-size-32--xs g-font-size-50--md g-letter-spacing--1 g-color--white g-margin-b-80--xs'>
							Save time and get more done at 30% less costs
						</h2>
						<div className='g-margin-b-0--xs  animated floatInUp'>
							<a target='_blank' href='https://cal.com/shubhamjadhav/gobananas-demo'>
								<span className='s-btn s-btn--sm s-btn--white-bg g-radius--50'>
									Book a demo <i className='material-symbols-sharp'>chevron_right</i>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<section id='pricing'>
				<Pricing />
			</section>

			<Snackbar open={wlAlertOpen} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={wlAlert.severity} color={wlAlert.color} sx={{ width: "100%" }}>
					{wlAlert.message}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default LandingPage;
