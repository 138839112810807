const Pricing = () => {
	return (
		<>
			<div className='g-bg-color--sky-light'>
				<div className='container g-padding-y-80--xs g-padding-y-125--xs'>
					<div className='g-text-center--xs g-margin-b-80--xs'>
						<h2 className='g-font-size-32--xs g-font-size-36--md'>
							Allow your team to GoBananas <br />
							with the first 3 months on us
						</h2>
					</div>

					<div className='row g-row-col--5'>
						<div className='col-md-4 g-margin-b-10--xs g-margin-b-0--lg'>
							<div className='wow fadeInUp' data-wow-duration='.3' data-wow-delay='.1s'>
								<div className='s-plan-v1 g-text-center--xs g-bg-color--white g-padding-y-80--xs'>
									<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2'>Basic Use</p>
									<h3 className='g-font-size-22--xs g-color--dark g-margin-b-30--xs g-font-weight--700'>Free</h3>
									<ul className='list-unstyled g-ul-li-tb-5--xs g-margin-b-40--xs'>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 50 GB Space
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Unlimited Projects Creation
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Workflows capped at 10 steps
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 360p Streaming
										</li>
									</ul>
									<div className='g-margin-b-40--xs'>
										<span className='s-plan-v1__price-mark'>$</span>
										<span className='s-plan-v1__price-tag'>0</span>
									</div>
									<a
										target='_blank'
										href='https://cal.com/shubhamjadhav/gobananas-demo'
										type='button'
										className='s-btn s-btn--sm s-btn--primary-bg g-radius--50 g-padding-x-50--xs'>
										Book a demo
									</a>
								</div>
							</div>
						</div>
						<div className='col-md-4 g-margin-b-10--xs g-margin-b-0--lg'>
							<div className='wow fadeInUp' data-wow-duration='.3' data-wow-delay='.2s'>
								<div className='s-plan-v1 g-text-center--xs g-bg-color--white g-padding-y-80--xs'>
									<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2'>For Professionals</p>
									<h3 className='g-font-size-22--xs g-color--dark g-margin-b-30--xs g-font-weight--700'>Bananas</h3>
									<ul className='list-unstyled g-ul-li-tb-5--xs g-margin-b-40--xs'>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 2 TB Space 2 TB + 5 TB Archival
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Unlimited Projects & Task Creation
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Unlimited Workflow steps
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Access to Publishing (5 Channels)
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 720p Streaming
										</li>
									</ul>
									<div className='g-margin-b-40--xs'>
										<span className='s-plan-v1__price-mark'>$</span>
										<span className='s-plan-v1__price-tag'>35</span>
										<span className='s-plan-v1__price-mark'>/month</span>
									</div>
									<a
										target='_blank'
										href='https://cal.com/shubhamjadhav/gobananas-demo'
										type='button'
										className='s-btn s-btn--sm s-btn--primary-bg g-radius--50 g-padding-x-50--xs'>
										Book a demo
									</a>
								</div>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='wow fadeInUp' data-wow-duration='.3' data-wow-delay='.3s'>
								<div className='s-plan-v1 g-text-center--xs g-bg-color--white g-padding-y-80--xs'>
									<p className='text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2'>For Studios</p>
									<h3 className='g-font-size-22--xs g-color--dark g-margin-b-30--xs g-font-weight--700'>Bananas Pro</h3>
									<ul className='list-unstyled g-ul-li-tb-5--xs g-margin-b-40--xs'>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 10 TB Space + 20 TB Archival
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Option to Adjust Space
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Unlimited Projects & Task Creation
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Unlimited Workflow steps
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> Access to Publishing (5 Channels)
										</li>
										<li>
											<i className='g-font-size-13--xs g-color--primary g-margin-r-10--xs ti-check'></i> 1080p Streaming
										</li>
									</ul>
									<div className='g-margin-b-40--xs'>
										<span className='s-plan-v1__price-mark'>$</span>
										<span className='s-plan-v1__price-tag'>125</span>
										<span className='s-plan-v1__price-mark'>/month</span>
									</div>
									<a
										target='_blank'
										href='https://cal.com/shubhamjadhav/gobananas-demo'
										type='button'
										className='s-btn s-btn--sm s-btn--primary-bg g-radius--50 g-padding-x-50--xs'>
										Book a demo
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Pricing;
